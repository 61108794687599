
import svgTwo from '../../images/2.png';
import svgThree from '../../images/3.png';

export const homeObjOne = {
  id: 'about',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Features',
  headline: 'Uncorrelated, decentralized, liquid and scalable with relatively low annualized volatility ',
  description:
    'The Synchronized Protocols native token WTISDR is a non-dilutive, supply-elastic, utility BEP-20 token built on the Binance Smart Chain.',
  
  imgStart: false,
  dark: true,
  primary: true,
  darkText: false,
};

export const homeObjTwo = {
  id: 'discover',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Connecting traditional economy and DeFi',
  headline: 'WTI and SDR',
  description:
    'Synchronized Protocol "Sprotocol" introduces the first crypto hedge utilizing the Crude Oil WTI futures via Chainlink, which is providing tamper-proof blockchain oracles for hybrid smart contracts. Additionaly, the Synchronized Protocol token is pegged to the  Special Drawing Rights (SDR). SDR is an international reserve asset, composed of the U.S. Dollar, Euro, GBP, Chinese Yuan, and Japanese Yen. ',
 
  imgStart: true,
  img: svgTwo,
  alt: 'Piggybank',
  dark: false,
  primary: false,
  darkText: true,
};

export const homeObjThree = {
  
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Research',
  headline: 'Correlation and annualized volatility',
  description:
    'There is nearly no correlation between the price movements of WTI/USD and BTC/USD. The result is in line with the aim to introduce the Synchronized Protocol to the market without being correlated to Bitcoin. Besides the annualized volatility of the two rebase input variables is considerably lower than Bitcoins annualized volatility. Detailed analysis can be found in the whitepaper. ',
 
  imgStart: false,
  img: svgThree,
  alt: 'Papers',
  dark: false,
  primary: false,
  darkText: true,
};
