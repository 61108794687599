import React from 'react';

import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from './ServicesElements';

import Icon2 from '../../images/6.png';


const Services = () => {
  return (
    <>
      <ServicesContainer id='services'>
        <ServicesH1></ServicesH1>
        <ServicesWrapper>
          <ServicesCard>
         
            <ServicesH2>Crude Oil WTI Futures</ServicesH2>
            <ServicesP>
            Oil futures and options markets are among the most liquid and actively traded global commodities contracts in the world. Especially, the oil futures market serves as important mechanism to measure the global economic sentiment. Besides oil is the largest component of most leading commodity price indexes.
            </ServicesP>
          </ServicesCard>
          <ServicesCard>
            <ServicesIcon src={Icon2} />
            <ServicesH2></ServicesH2>
            <ServicesP>
            The protocol is utilizing the two synchronized rebase input variables - WTI and SDR - as transmitters of the global economic sentiment pulse into DeFi. 
            </ServicesP>
          </ServicesCard>
          <ServicesCard>
           
            <ServicesH2>SDR</ServicesH2>
            <ServicesP>
            The reason for pegging to the SDR is due to the fact that shocks in commodity futures settlement prices inversely affect the values of USD in other currencies. Generally, there are pronounced and rather immediate inverse reactions of exchange rates to changes in commodity futures prices.  
            </ServicesP>
          </ServicesCard>
        </ServicesWrapper>
      </ServicesContainer>
    </>
  );
};

export default Services;
