import React from 'react';

import {
  SideBarContainer,
  Icon,
  CloseIcon,
  SideBarWrapper,
  SideBarMenu,
  SideBarLink,
  SideBtnWrap,
  SideBarRoute,
} from './SideBarElements';

const SideBar = ({ isOpen, toggle }) => {
  return (
    <>
      <SideBarContainer isOpen={isOpen} onClick={toggle}>
        <Icon>
          <CloseIcon onClick={toggle} />
        </Icon>

        <SideBarWrapper>
          <SideBarMenu>
            <SideBarLink to='about' onClick={toggle}>
            
            </SideBarLink>
            <SideBarLink as="a" href="wp.pdf"  target="_blank" >
            
              Whitepaper
            </SideBarLink>
            <SideBarLink to='services' onClick={toggle}>
            
            </SideBarLink>
            <SideBarLink to='signup' onClick={toggle}>
          
            </SideBarLink>
          </SideBarMenu>

          <SideBtnWrap>
           
          </SideBtnWrap>
        </SideBarWrapper>
      </SideBarContainer>
    </>
  );
};

export default SideBar;
