import React, { useState } from 'react';

import video from '../../videos/video.mp4';
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
} from './HeroElements';
import { Button } from '../ButtonElement';

const HeroSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <>
      <HeroContainer id='home'>
        <HeroBg>
          <VideoBg autoPlay loop muted src={video} type='video/mp4' />
        </HeroBg>

        <HeroContent>
          <HeroH1>Synchronized Protocol</HeroH1>
          <HeroP>
            Transmitting the pulse of traditional finance into DeFi
          </HeroP>
          <HeroBtnWrapper>
            <Button
               as="a" href="https://bunicorn.exchange/#/liquidity/tokens/detail/0x82730b866f20202ade4c8c72e1508fd5e33e193a/" target="blank" 
              onMouseEnter={onHover}
              onMouseLeave={onHover}
              primary='true'
              dark='true'
              smooth={true}
              duration={500}
              spy={true}
              exact='true'
              offset={-10}
            >
              <b>Purchase WTISDR tokens</b>
            </Button>
          </HeroBtnWrapper>
        </HeroContent>
      </HeroContainer>
    </>
  );
};

export default HeroSection;
