import React from 'react';

import {

  FaMedium,
  FaGithub,
  FaTwitter,
  FaDiscord,
  FaTelegram,
} from 'react-icons/fa';
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLink,
  FooterLinkTitle,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
} from './FooterElements';
import { animateScroll as scroll } from 'react-scroll';

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <FooterContainer>
        <FooterWrap>
          <FooterLinksContainer>
            <FooterLinksWrapper>
              <FooterLinkItems>
              
                <FooterLink as="a" href="https://bunicorn.exchange/#/liquidity/tokens/detail/0x82730b866f20202ade4c8c72e1508fd5e33e193a/" target="_blank" >Purchase WTISDR tokens</FooterLink>
               
                
               
              </FooterLinkItems>

              <FooterLinkItems>
               
                <FooterLink as="a" href="https://bscscan.com/address/0x365aFC3352C1436657dFF40c1Ef4E4EfdE238d27#code" target="_blank">BSCscan</FooterLink>
               
              </FooterLinkItems>
            </FooterLinksWrapper>

            <FooterLinksWrapper>
              <FooterLinkItems>
             
                <FooterLink as="a" href="pp.pdf"  target="_blank">Privacy Policy</FooterLink>
                 
              
              </FooterLinkItems>

              <FooterLinkItems>
            
                <FooterLink as="a" href="tos.pdf"  target="_blank">Terms of Service</FooterLink>
         
              </FooterLinkItems>
            </FooterLinksWrapper>
          </FooterLinksContainer>

          <SocialMedia>
            <SocialMediaWrap>
              <SocialLogo to='/' onClick={toggleHome}>
                ǁ SYNCHRONIZED Protocol ǁ
              </SocialLogo>
              <br></br>
              <WebsiteRights>
                &copy; {new Date().getFullYear()}                                                                                                                            . All Rights
                Reserved.
              </WebsiteRights>
              <SocialIcons>
            
                <SocialIconLink
                  href='https://medium.com/@synchronizedprotocol'
                  target='_blank'
                  aria-label='facebook'
                >
                   <FaMedium />
                </SocialIconLink>
                <SocialIconLink
                  href='https://github.com/SynchronizedProtocol'
                  target='_blank'
                  aria-label='medium'
                >
                   <FaGithub />
                </SocialIconLink>
                <SocialIconLink
                  href='https://twitter.com/synchronizedp'
                  target='_blank'
                  aria-label='github'
                >
                 <FaTwitter/>
                </SocialIconLink>
                <SocialIconLink
                  href='https://discord.gg/WUduc9DdWs'
                  target='_blank'
                  aria-label='twitter'
                >
                   <FaDiscord />
                </SocialIconLink>
                <SocialIconLink
                  href='https://t.me/Synchronized_Protocol'
                  target='_blank'
                  aria-label='discord'
                >
                  <FaTelegram />
                </SocialIconLink>
                <SocialIconLink
                  href=''
                  target='_blank'
                  aria-label='telegram'
                >
                 
               
                 
                 
                </SocialIconLink>
              </SocialIcons>
            </SocialMediaWrap>
          </SocialMedia>
        </FooterWrap>
      </FooterContainer>
    </>
  );
};

export default Footer;
