import React, { useState, useEffect } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { FaBars } from 'react-icons/fa';



import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
} from './NavBarElements';

const NavBar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);

    return () => {
      window.removeEventListener('scroll', changeNav);
    };
  }, []);

  return (
    <>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <NavLogo to='/' onClick={toggleHome}>
            ǁ SYNCHRONIZED Protocol ǁ
          </NavLogo>

          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>

          <NavMenu>
          
            <NavItem>
            <NavLinks as="a" href="https://bunicorn.exchange/#/liquidity/tokens/detail/0x82730b866f20202ade4c8c72e1508fd5e33e193a/" target="_blank" >
   Purchase WTISDR tokens
 </NavLinks>
             
            </NavItem>
            <NavItem>
              <NavLinks as="a" href="wp.pdf"  target="_blank"
              >
                Whitepaper
              </NavLinks>
            </NavItem>
           
            
          </NavMenu>

          
        </NavbarContainer>
      </Nav>
    </>
  );
};



export default NavBar;
